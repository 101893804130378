@import 'styles/responsive.less';
@import 'styles/variables.less';

.journal-tags {
  margin-top: 30px;

  .heal-from-home {
    background: #fefaf7;
    width: max-content;
  }
}
