@import 'styles/responsive.less';
@import 'styles/variables.less';

.calendarDay {
  border-top: 2px solid @eggshell;

  padding: 25px 0px;

  @media @mobile {
    button {
      width: 100%;
    }
  }

  &__top {
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    color: #0d345c;
    letter-spacing: -0.4px;

    .icon svg {
      margin-right: 5px;
      @media @desktop {
        margin-right: 28px;
      }
      height: 8px;
    }
  }

  &__slots {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    margin-bottom: 20px;
    flex-direction: column;
    margin-top: 20px;

    @media @desktop {
      gap: 10px;
    }

    &.loading {
      display: block;
      height: 180px;
      padding-top: 25px;
    }

    .loader {
      margin: auto;
      height: 64px;
      width: 65px;
    }
  }
  &__slot {
    cursor: pointer;
    display: block;
    background: #f3f3f3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 12px 10px;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.4px;

    @media @desktop {
      padding: 28px 23px;
    }

    &.selected,
    &:hover {
      background: #0d345c;
      color: #f3f3f3;
    }
  }
}
