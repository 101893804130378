@import 'styles/variables.less';
@import 'styles/responsive.less';
@import 'styles/easing.less';

.journal-related {
  margin-bottom: 64px;

  h1 {
    font-size: 40px;
    line-height: 45px;

    @media @desktop {
      font-size: 70px;
      line-height: 100px;
    }
    font-family: @baskerville;

    letter-spacing: -0.6px;
    text-transform: lowercase;
    padding-bottom: 14px;
    border-bottom: 2px solid #fefaf7;
  }

  &__stories {
    display: flex;
    flex-direction: column;

    @media @desktop {
      flex-direction: row;
    }
    @media @tablet {
      flex-direction: row;
    }
    justify-content: space-between;
    column-gap: 24px;
  }

  .story {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 448px;
    padding-top: 48px;
    padding-bottom: 48px;
    @media @mobile {
      border-bottom: 2px solid #d2cfc9;
    }

    &__cover {
      background-color: #c5d3df;
      border-radius: 30px;
      height: 252px;
      width: 100%;
      object-fit: cover;

      @media @tablet {
        height: 150px;
      }
    }

    &__author {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        border-radius: 64px;
        margin-right: 10px;
        height: 30px;
      }
      span {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.3px;
        font-family: @gothamBook;
      }
    }
    &__title {
      margin-top: 25px;
      padding: 0 12px;

      h4 {
        text-transform: lowercase;
        font-family: @baskerville;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.3px;
        margin-bottom: 15px;
      }
    }
    &__description {
      flex: 1;
      p {
        font-family: @gothamBook;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.4px;
        margin-bottom: 20px;
      }
      a {
        font-family: @gotham;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        svg {
          height: 18px;
          margin-right: 8px;
        }
      }
    }
  }
}
