@import 'styles/responsive.less';
@import 'styles/variables.less';

@import 'styles/responsive.less';
@import 'styles/variables.less';

.planSelected {
  width: 100%;
  border-radius: 30px;

  background: #f1f1f1;
  color: @nueblue;
  padding: 20px;

  &.wellness {
    .button.border {
      border-color: @wellness !important;
    }
    .button.long {
      background: @wellness;
      color: @nueblue;
    }

    li.highlight {
      background: @wellness;
      color: @nueblue;
      .icon {
        color: @sleep;
      }
    }
  }

  .button {
    width: 100%;
  }

  &__name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .name {
      font-family: @baskerville;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }

    .details {
      .text {
        display: none;
      }
    }
  }

  &__description {
    margin-top: 41px;
    font-family: @gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    height: 100px;
    overflow: hidden;
  }

  &__type {
    color: @warmblue;
  }

  &__bottom {
    padding: 20px;
  }

  &__features {
    margin-top: 30px;
    padding-left: 30px;
  }

  &__textWrapper {
    position: relative;
  }

  &__textWrapper:after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(243, 243, 243, 0) 0%,
      #f3f3f3 100%
    );
    pointer-events: none; /* so the text is still selectable */
  }

  &__itemWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
    padding: 18px 30px;

    a {
      white-space: nowrap;
      margin-left: 10px;
      min-width: 10px;
      display: flex;
      .icon {
        margin-left: 5px;
        color: @warmblue;
      }
    }

    .text {
      font-family: @gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -0.4000000059604645px;
      text-align: left;
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        color: @warmblue;
        margin-right: 18px;
      }
      img {
        margin-right: 18px;
      }
    }
  }

  @media @desktop {
    padding: 40px;
    &__name {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      .name {
        font-family: Baskerville;
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: 70px;
        letter-spacing: -0.30000001192092896px;
        text-align: left;
      }

      .details {
        .text {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .button.long {
      width: 250px;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-right: 40px;
    }

    &__leftSpace {
      margin-left: 100px;
    }

    &__features {
      margin-left: 100px;
      padding-left: 0;
    }
  }

  &__itemDetailsArrow {
    white-space: nowrap;
  }

  .divider {
    background: #c8d2de;
    border-radius: 2px;
    height: 2px;
    margin: 0;
  }

  .planPrice {
    margin-top: 49px;
    margin-bottom: 29px;
  }

  .subtitle {
    margin-top: 1vh;
  }

  h3 {
    font-family: @baskerville;
    font-size: 70px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
  }
}
