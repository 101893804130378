@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

:global(#nue-network-hero) {
  padding-bottom: 40px;
  .header {
    font-family: @baskervilleRegular;
    font-size: 30px;
    line-height: 1;

    @media @desktop {
      font-size: 70px;
      line-height: 80px;
    }
  }

  .header2 {
    font-family: @baskerville;
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 24px 0;

    @media @desktop {
      font-size: 62px;
      line-height: 1;
      margin-bottom: 40px;
      text-transform: lowercase;
    }
  }

  .bodytext {
    font-size: 14px;
    line-height: 22px;
    font-family: @gothamBook;

    @media @desktop {
      font-size: 18px;
    }

    b {
      font-family: @gotham;
      font-weight: normal;
    }
    :global(b.mb) {
      display: block;
      margin-bottom: 8px;
    }
  }
}
