@import 'styles/responsive.less';
@import 'styles/variables.less';

.planPriceVariant {
  color: @eggshell;

  display: flex;
  flex-direction: row;

  &__item {
    margin-right: 1em;
    display: flex;
    flex-direction: row;

    &.total {
      .planPriceVariant__amount {
        font-size: 35px;
      }
    }
  }

  &__divider {
    background: @wellness;
    width: 2px;
    height: 50px;
    display: inline-block;
    margin-left: 2em;
    margin-top: 10px;
  }

  &__currency {
    color: @wellness;
    margin-top: -1em;
  }

  .total .planPrice__type {
    display: inline;
  }

  &__type {
    display: block;
    color: @wellness;
    margin-left: 10px;

    font-family: @gotham;
    font-size: 14px;
    font-style: normal;

    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
  }

  &__amount {
    color: @eggshell;
    font-family: @baskervilleRegular;
    font-size: 70px;
    font-style: italic;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: -2px;
  }
}
