@font-face {
  font-family: 'Baskerville Italic';
  src: url('../assets/fonts/baskerville/Baskerville-Italic.woff2')
      format('woff2'),
    url('../assets/fonts/baskerville/Baskerville-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Baskerville Regular';
  src: url('../assets/fonts/baskerville/Baskerville.woff2') format('woff2'),
    url('../assets/fonts/baskerville/Baskerville.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/gotham/Gotham-Medium.woff2') format('woff2'),
    url('../assets/fonts/gotham/Gotham-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../assets/fonts/gotham/Gotham-Book.woff2') format('woff2'),
    url('../assets/fonts/gotham/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../assets/fonts/montserrat/Montserrat.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Baskersvville Regular';
  src: url(../assets/fonts/baskerville/Baskervville-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Baskersvville Italic';
  src: url(../assets/fonts/baskerville/Baskervville-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
