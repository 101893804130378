@import 'styles/variables.less';

.progress_bar--background{
    background-color: @nueblue;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
}

.progress_bar{
    width: 80%;
    height: 20px;
    border-radius: 5px;
    background-color: @eggshell;
    overflow: hidden;
}

.progress{
    transition: 0.3s width;
    background-color: @progressBarBlue;
    height: 100%;
}

.progress--value{
    margin:0 10px;
    color: @newLandingWhite;
}

.progress_bar--under_background{
    background-color: @nueblue;
    position: absolute;
    width: 100%;
    z-index: -100;
    height: 50vh;
}