@import 'styles/responsive.less';
@import 'styles/variables.less';

.appFooter {
  // background: url('assets/footer-app-mask.png') @nueblue;
  background: @warmblue;
  background-size: cover;
  padding-top: 50px;

  .subtitle {
    font-family: @gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;
    color: @warmgrey;
  }

  &__title {
    color: @eggshell;
    text-align: center;
    padding-bottom: 0.5em;

    @media @desktop {
      padding: 0 !important;
    }
    .italic {
      font-family: @baskerville;
    }

    .company {
      font-style: normal;
    }
  }

  &__download {
    //styleName: H2;
    font-family: @baskerville;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.30000001192092896px;
    margin-top: 20px;
  }

  &__downloadLinks {
    margin-bottom: 3em;
  }

  &__image {
    padding-bottom: 40px;
    text-align: center;
    img {
      width: 280px;
      text-align: left;
    }
  }

  &__text {
    color: @eggshell;
    p {
      @media @desktop {
        margin-top: 80px;
      }
    }

    img {
      margin-top: 1em;
      max-width: 170px;
    }
  }

  @media @desktop {
    padding-top: 93px;

    &__title {
      font-size: 70px;
      font-weight: 400;
      line-height: 110px;
      letter-spacing: -0.30000001192092896px;
      text-align: center;

      letter-spacing: -0.30000001192092896px;
      text-align: center;

      .company {
        font-style: normal;
      }
    }

    &__content {
      margin-top: 2em;
      display: flex;
      flex-direction: row-reverse;

      img {
        margin-top: 1em;
        width: 190px;
      }
    }

    &__image {
      margin-top: 6em;
      display: flex;
      flex-direction: row;
      flex: 1;
      padding-bottom: 0;
      align-items: flex-start;

      .offset {
        margin-right: 28px;
        padding-top: 150px;
        img {
          vertical-align: bottom;
        }
      }
      img {
        width: 280px;
      }
    }

    &__text {
      flex: 1;
      margin-left: 130px;
    }

    p {
      //styleName: Body Copy Long Read;
      font-family: @gotham;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.5px;
      text-align: left;
    }

    &__download {
      font-family: @baskerville;
      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }
  }
}
