@import 'styles/responsive.less';
@import 'styles/variables.less';

.journal-tag {
  display: inline-block;
  background: #fefaf7;
  margin-right: 5px;
  border-radius: 30px;
  font-family: @baskerville;
  letter-spacing: -0.3px;
  text-transform: lowercase;
  font-size: 16px;
  line-height: 18px;
  padding: 6px 15px;
  margin-bottom: 5px;
  white-space: nowrap;

  &.heal-from-home {
    border: 1.5px solid #fefaf7;
    background: transparent;
    border-radius: 100px;
    font-family: @gotham;
    font-size: 13px;
    line-height: 16px;
    text-transform: inherit;

    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 4px;

    &:hover {
      background: #fefaf7;
    }

    .icon {
      vertical-align: sub;
      padding-top: 3px;
      padding-bottom: 1px;
      width: 14.69px;
      height: 14.69px;
      margin-right: 9px;
      margin-left: -4px;
    }
  }
}
