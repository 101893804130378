@import 'styles/responsive.less';
@import 'styles/variables.less';

.review {
  flex: 1;
  color: @nueblue;
  padding-bottom: 2em;
  @media @desktop {
    padding-right: 2em;
  }

  .line {
    margin-top: 1em;
    width: 100%;
    background: @love;
    height: 2px;
    border-radius: 2px;
  }

  .divider {
    background: #e1d8cf;
    margin-top: 1em;
    margin-bottom: 1em;
    height: 2px;
    border-radius: 2px;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &__quote {
    text-align: left;
  }

  &__profile {
    margin-top: 1em;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    .name {
      //styleName: Body Copy 2;
      font-family: @gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.4000000059604645px;
      text-align: left;
    }

    img {
      height: 59px;
      margin-right: 10px;
    }
  }

  .rating {
    position: relative;
    display: inline-flex;
    justify-items: center;
    background: @love;
    border-radius: 100px;
    font-family: @gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: -0.4px;
    padding: 3px 12px;
    align-items: flex-start;
    .icon {
      position: absolute;
      top: 4px;
    }
    .text {
      margin-left: 20px;
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 60px;
  }

  @media @desktop {
    margin-right: 22px;

    &__quote {
      text-align: left;
    }
  }
}
