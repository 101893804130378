@import 'styles/responsive.less';
@import 'styles/variables.less';

.textarea {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px 10px 20px;

  background: @eggshell;
  opacity: 0.8;
  border-radius: 10px;
  width: 100%;
  input {
    width: 100%;
  }

  @media @desktop {
    width: 340px;
  }

  /* Eggshell */

  background: #f3f3f3;
  opacity: 0.6;
  border-radius: 10px;

  &.hasValue {
    opacity: 1;
  }

  label {
    font-family: Gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    color: @calmblue;
  }

  textarea {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    resize: none;
    font-family: @gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }
}
