@import 'styles/responsive.less';
@import 'styles/variables.less';

.container {
  padding: 60px 30px;

  h3 {
    font-family: @baskerville;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.3px;

    @media @desktop {
      font-size: 50px;
      line-height: 60px;
      letter-spacing: -1.3px;
    }
  }
  h5 {
    font-family: @gothamBook;
    font-size: 14px;
    margin-top: 16px;
    text-align: center;
    @media @desktop {
      margin-top: 24px;
      font-size: 16px;
    }
  }

  p.teamCommitment {
    font-family: @baskerville;
    font-size: 26px;
    line-height: 1;
    letter-spacing: -0.3px;
    text-align: center;
    max-width: 760px;
    margin: auto;
    margin-top: 20px;

    a {
      text-decoration: underline;
    }
  }

  .teamList {
    margin: 60px 0 80px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 60px;
  }

  .teamMember {
    display: flex;
    flex-direction: row;

    gap: 16px;

    @media @desktop {
      flex-direction: column;
      margin-bottom: 55px;
      gap: 24px;
    }

    a.linkedin {
      display: inline-block;
      height: 45px;
      width: 45px;

      svg {
        height: 45px;
        width: 45px;
      }
    }

    .teamMember__image {
      position: relative;
      width: 100px;
      height: 100px;
      flex-shrink: 0;

      a {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -23px;
      }

      img {
        border-radius: 20px;
        width: 400px;
        height: 400px;
        object-fit: cover;
        @media @mobile {
          width: 100%;
        height: 100%;
        }
      }

      @media @desktop {
        width: 100%;
        height: auto;
        img {
          border-radius: 30px;
        }
        a {
          display: none;
        }
      }
    }

    .teamMember__info {
      h4 {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.3px;
        margin-bottom: 6px;
      }
      p {
        font-family: @gothamBook;
        font-size: 14px;
        line-height: 20px;
      }
      a {
        display: none;
      }

      @media @desktop {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        a {
          display: inline-block;
        }
      }
    }
  }
}
