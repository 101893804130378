@import 'styles/easing.less';
@import 'styles/responsive.less';
@import 'styles/variables.less';
.plansForUpgrade {
  .plan {
    width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-content: space-between;
    justify-content: space-between;
    background: #f1f1f1;
    color: @eggshell;

    &.calmblue {
      background: @calmblue;

      .plan__textWrapper {
        &:after {
          background: linear-gradient(
            180deg,
            rgba(75, 121, 170, 0) 0%,
            #4b79aa 100%
          );
        }
      }
    }

    &.nueblue {
      background: lighten(@nueblue, 6);
      .plan__textWrapper {
        &:after {
          background: linear-gradient(
            180deg,
            rgba(13, 52, 91, 0) 0%,
            #114377 100%
          );
        }
      }
    }

    .icon.arrow {
      width: 10px;
    }

    .button {
      width: 100%;
    }

    .button.readmore {
      color: @eggshell;
    }

    &__type {
      font-family: @gotham;
      text-transform: uppercase;
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      padding-top: 0.5em;
    }

    .title {
      padding: 0 !important;
      text-align: left;
    }

    &__name {
      .emp {
        font-family: @baskerville;
      }
    }

    &__description {
      margin-top: 41px;
      font-family: @gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.4000000059604645px;
      overflow: hidden;
      transition: height 0.2s @easeInOutCubic;
    }

    &__top {
      padding: 20px;
      padding-bottom: 0;
    }

    &__bottom {
      padding: 20px;
    }

    &__features {
      margin-top: 30px;
      //padding-left: 30px;
    }

    &__featureDescription {
      //margin-left: 62px;
      padding-right: 20px;
      font-size: 12px;
    }

    &__textWrapper {
      position: relative;
    }

    &__textWrapper {
      &:after {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        content: '';
        pointer-events: none; /* so the text is still selectable */
        opacity: 0;
        transition: opacity 0.2s linear;
        background: linear-gradient(
          180deg,
          rgba(243, 243, 243, 0) 0%,
          var(--backgroundColor) 100%
        );
      }

      &--fade:after {
        opacity: 1;
      }
    }

    &__itemWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-items: center;
      padding: 18px 30px;

      a {
        white-space: nowrap;
        margin-left: 10px;
        min-width: 10px;
        display: flex;
        .icon {
          margin-left: 5px;
          width: 12px;
        }
      }

      .text {
        font-family: @gotham;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.4000000059604645px;
        text-align: left;
      }

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon {
          margin-right: 18px;
          width: 12px;
        }
        img {
          margin-right: 18px;
        }
      }
    }

    @media @desktop {
      width: 700px;
      padding-right: 0;

      .button.long {
        width: 250px;
      }
      &__bottom{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

      &__bottom_item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button {
          margin-top: 20px;
        }
      }
    }
      &__leftSpace {
        margin-left: 0px;
      }

      &__features {
        margin-left: 0px;
        padding-left: 5px;
      }
    }

    &__itemDetailsArrow {
      white-space: nowrap;
    }

    .divider {
      background: #c8d2de;
      border-radius: 2px;
      height: 2px;
      margin: 0;
    }

    .planPrice {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .subtitle {
      margin-top: 1vh;
    }

    h3 {
      font-family: @baskerville;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }

    .planPriceVariant {
      @media @mobile {
        margin-bottom: 20px;
      }
    }
  }
}