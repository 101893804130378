@import 'styles/variables.less';
@import 'styles/responsive.less';

.section {
  border-radius: 24px;

  @media @desktop {
    border-radius: 50px;
  }

  position: relative;
  box-sizing: border-box;
  z-index: 1;

  background: @eggshell;

  &.flat {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.eggshell {
    background: @eggshell;
  }

  &.sleep {
    background: @sleep;
  }

  &.warmgrey {
    background: @warmgrey;
  }

  &.wellness {
    background: @wellness;
  }

  &.calmblue {
    background: @calmblue;
  }

  &.nueblue {
    background: @nueblue;
  }

  &.changeyellow {
    background: @changeyellow;
    color: @nueblue;
    background-size: auto;
    .divider {
      background: @nueblue;
    }
  }
}
