@import 'styles/responsive.less';
@import 'styles/variables.less';

@keyframes progress {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

.loader {
  width: 126px;
  height: 126px;
  border: 5px solid #0d345c;
  background: #f2ede8;
  border-radius: 63px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  &__progress {
    width: 100%;
    height: 40%;
    background: #0d345c;
    animation-duration: 1.8s;
    animation-name: progress;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}
