@import 'styles/variables.less';
@import 'styles/responsive.less';
@import 'styles/easing.less';

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -100;
  padding-top: 120px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @media @desktop {
    padding-top: 125px;
  }

  &.active {
    opacity: 1;
    z-index: 100;
  }

  &.has-value {
    .search-overlay__input_btn {
      background: #4b79aa;
      color: @eggshell;
    }
  }

  &__background {
    position: fixed;
    top: 0;
    background: #f2ede8;
    opacity: 0.8;
    height: 100vh;
    width: 100vw;
  }

  &__input-wrapper {
    height: 70px;
    padding: 0 30px;

    @media @desktop {
      height: 130px;
      padding: 0 60px;
    }
    background: #fefaf7;
    border: 1.5px solid #fefaf7;
    border-radius: 100px;

    box-sizing: border-box;
    position: relative;
    z-index: 5;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__input_btn {
    cursor: pointer;
    background: #f2ede8;
    border: 1.5px solid #fefaf7;
    height: 50px;
    width: 54px;
    border-radius: 50px;
    display: flex;

    align-items: center;
    justify-content: center;
    margin-right: -22px;
    @media @desktop {
      margin-right: -47px;
    }
    color: @nueblue;

    .icon {
      height: 15px;
      width: 15px;
    }

    @media @desktop {
      display: flex;
      height: 100px;
      width: 107px;
      margin-right: -40px;
      .icon {
        height: 26px;
        width: 26px;
      }
    }
  }

  &__input {
    font-size: 22px;
    line-height: 34px;
    @media @desktop {
      font-size: 50px;
      line-height: 56px;
    }

    letter-spacing: -0.3px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-family: @baskerville;
    box-sizing: border-box;
    color: @nueblue;
    letter-spacing: -0.3px;
  }
}
