* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden !important;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

a {
  cursor: pointer;
}

a:focus {
  outline: none;
}

a img {
  display: block;
}

p,
dl,
dd {
  margin: 0;
}

input,
button,
textarea {
  color: inherit;

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 0.3;
    color: inherit;
  }

  border: none;
  font: inherit;
  background: none;

  // Careful of specificity here
  &:not([type='checkbox']) {
    -webkit-appearance: none;
  }
}

em {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-size: inherit;
  line-height: 1.2;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
