@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

.discover {
  position: relative;
  overflow: hidden;

  h2 :not(.plan) {
    font-size: 45px;
    line-height: 1em;
    font-family: @baskerville;
    span  {
      display: block;
      font-size: 24px;
      line-height: 24px;
    }
  }
  h1 :not(.plan) {
    font-size: 40px;
    line-height: 1em;
    font-family: @baskervilleRegular;
    margin-bottom: 40px;
  }

  .top-nav-variant .logo-with-text svg.resize-mobile {
    width: 123px;
    height: 52.5px;
    @media @desktop {
      width: auto;
      height: auto;
    }
  }

  .button.secondary.phone {
    span.icon {
      width: 18px;
      height: 18px;
      vertical-align: sub;
      margin-right: 5px;
    }
  }

  .logo {
    vertical-align: bottom;
  }

  .title {
    padding-top: 1em;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
    padding-bottom: 1.5em;

    @media @mobile {
      padding-top: 0.2em;
      padding-bottom: 1em;
    }

    @media @desktop {
      text-align: left;
      padding-left: 2em;
    }

    .company {
      font-family: @baskervilleRegular;
      font-style: normal;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }
  }

  .statement-title {
    margin-top: 2vh;
  }

  .welcome {
    position: relative;
    z-index: 1;

    background-size: cover !important;
    background: url('../assets/landing-bg-mobile.png') @calmblue;

    @media @desktop {
      background: url('assets/discover-landing-wide.jpg') @calmblue;
    }

    .column.image {
      display: none;
      @media @desktop {
        display: flex;
        align-items: flex-end;
      }
    }

    .container {
      padding-bottom: 100px;
      padding-top: 120px;
    }

    .inner {
      min-height: 70vh;
      display: flex;
      flex-direction: column;
      gap: 100px;

      @media @desktop {
        flex-direction: row;
      }

      justify-content: center;
      color: @eggshell;
      text-align: left;

      button {
        text-align: center;
      }

      img {
        display: block;
        width: 100%;
        max-width: 570px;
        border-radius: 30px;
      }
    }

    a.button {
      margin-top: 2em;

      @media @mobile {
        display: block;
      }
    }

    button {
      margin: auto;
      margin-top: 1em;
      text-align: center;

      @media @desktop {
        position: absolute;
        left: 50%;
        margin-left: -22px;
        width: 44px;
        bottom: 100px;
      }
    }

    .logo-mobile {
      margin-top: 45px;
      position: absolute;
      z-index: 2;
      margin-right: auto;
      margin-left: auto;
      width: 100%;

      @media @desktop {
        display: none;
      }
    }

    .subtitle {
      color: @eggshell;
      margin-bottom: 2em;
    }

    .column {
      flex: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;

      @media @desktop {
        align-items: flex-start;
      }
    }
  }

  .discover {
    padding-bottom: 5em;
    @media @desktop {
      padding-top: 2em;
    }

    @media @mobile {
      .h1 {
        margin-top: 1em;
        font-size: 35px;
        line-height: 35px;
      }
    }

    .italic {
      font-family: @baskerville;
      font-size: 24px;
      line-height: 30px;
    }
  }

  .discover__video {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    .adaptive-video {
      border-radius: 30px;
      //height: 520px;
      @media @desktop {
        max-width: 350px;
      }
      width: 100%;
    }
  }

  .discover__content {
    flex: 1;
    margin-top: 0em;

    @media @desktop {
      margin-top: -2.8em;
    }

    p {
      margin-top: 3em;
      margin-bottom: 1.5em;
    }

    .qoute {
      padding-top: 4em;
    }
  }

  .our_process {
    background: #c5d3df;
    margin-bottom: 3em;
    border-radius: 24px;
    @media @desktop {
      border-radius: 48px;
    }

    .container {
      padding-top: 40px;
      @media @desktop {
        padding: 100px;
      }
    }

    .title {
      padding: 0;
      padding-bottom: 1em;
      font-size: 40px;

      @media @desktop {
        font-size: 70px;
      }
    }

    .our_process_item {
      display: flex;
      position: relative;
      height: 400px;
      justify-content: center;
      max-width: 470px;
      margin: auto;
      margin-top: 2em;

      @media @desktop {
        position: relative;
        height: 400px;

        margin-top: 0;
        margin-right: 10em;

        &.offset {
          margin-right: 0;
          margin-left: 10em;
        }
      }

      &__background {
        height: 340px;
        width: 100%;
        max-width: 340px;

        @media @desktop {
          margin: 30px;
          width: 340px;
        }

        position: absolute;
        background: linear-gradient(
          360deg,
          #4b79aa 0%,
          rgba(75, 121, 170, 0.6) 100%
        );
        border-radius: 180px;
        opacity: 0.2;
        z-index: 0;
      }

      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
        position: relative;
        height: 100%;
        @media @desktop {
          margin-left: 0em;
        }
      }

      &__number {
        font-size: 45px;
        font-family: @baskerville;
        line-height: 45px;
      }

      &__title {
        margin-top: 1em;
        font-size: 26px;
        line-height: 26px;
        font-family: @baskerville;
      }

      &__body {
        margin-top: 1em;
        font-size: 14px;
        line-height: 20px;
        font-family: @gothamBook;
      }

      &__images {
        flex: 0.5;
        margin-right: 2em;
        margin-left: 1.5em;
        @media @desktop {
          margin-left: 2em;
          margin-right: 1em;
        }
      }

      &__content {
        flex: 1;
      }

      .h1 {
        margin-bottom: 0.2em;

        font-family: @baskerville;
        font-size: 45px;
        line-height: 45px;
      }
    }
  }

  .not-alone {
    background: @calmblue;
    color: @eggshell;
    margin-top: -80px;
    padding-top: 80px;

    .header {
      margin: auto;
      max-width: 800px;

      text-align: center;
      @media @desktop {
        text-align: left;
      }

      margin-top: 2em;
      margin-bottom: 3em;

      .italic {
        font-family: @baskerville;
        font-size: 45px;
        line-height: 50px;
        @media @desktop {
          font-size: 60px;
          line-height: 60px;
        }
      }
    }
  }

  .reviews {
    background: url('assets/paul.jpg') #000;
    background-size: cover;
    padding-top: 1em;
    padding-bottom: 1em;

    .readmore {
      color: @nueblue;
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    @media @desktop {
      .title {
        margin-top: -140px;
        margin-left: 45vw;
      }

      .readmore {
        width: 320px;
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;

        .review {
          flex: 1 1 30%; /*grow | shrink | basis */
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
        }
      }
    }
  }

  .steps {
    margin-top: 4em;
    position: relative;
    z-index: 2;
    color: @eggshell;

    > .title {
      .italic {
        font-family: @baskerville;
      }
    }

    .offset {
      @media @desktop {
        margin-top: 7em;
      }
    }

    .container {
      flex: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      justify-content: center;

      .step {
        margin-bottom: 2em;
      }

      > .subtitle {
        margin-top: 10vh;
        color: @nueblue;
        text-align: center;
      }

      > .title {
        padding-top: 0.5em;
        text-align: center;
        color: @nueblue;
        @media @desktop {
          text-align: left;
        }
      }
    }
  }

  .features {
    background: @calmblue;
    padding-top: 2em;
    padding-bottom: 4em;

    > .container .title {
      color: @eggshell;
    }

    .carousel__item {
      @media @mobile {
        width: 95%;
      }
    }

    .feature {
      background: @wellness;
    }
  }

  .better-together {
    .layout {
      justify-content: center;
      .title {
        padding-bottom: 1em;
      }
    }
  }

  .plans {
    padding-bottom: 40px;
    color: @nueblue;
    &__image {
      flex: 1;
      img {
        border-radius: 30px;
        margin-top: 4vh;
        @media @desktop {
          max-width: 380px;
        }
        width: 100%;
      }
    }

    .plans__note {
      font-family: @baskerville;

      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }

    &__content {
      flex: 1;
      @media @mobile {
        margin-top: 4vh;
      }

      .title {
        padding: 0;
        margin: 0;
        text-align: left;
      }

      p {
        margin-top: 0.5em;
        margin-bottom: 2em;
        font-family: @gothamBook;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.5px;
      }

      .button {
        width: 255px;
        @media @desktop {
          width: 220px;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;

      .plan {
        margin-bottom: 2em;
      }

      @media @desktop {
        flex-direction: row;
        .plan {
          margin: 2em;
        }
      }
    }
  }
  .options {
    color: @nueblue;
    font-size: 18px;
    padding-bottom: 18px;
  }

  .sidenote {
    color: #9c988a;
  }

  .appFooter {
    background: @calmblue;
    padding-top: 70px;
    @media @desktop {
      padding-top: 120px;
    }
    z-index: 0;
    margin-top: -40px;
  }

  .testimonials {
    padding-top: 4em;
    padding-bottom: 3em;

    @media @desktop {
      padding-top: 130px;
      padding-bottom: 120px;
    }
    background-color: #c5d3df;
    border-bottom-right-radius: 48px;
    border-bottom-left-radius: 48px;

    .title {
      max-width: 420px;

      @media @desktop {
        position: relative;
        left: -100px;
      }
    }

    &__item {
      font-family: @gothamBook;

      @media @desktop {
        width: 33.33%;

        &:not(:first-child) {
          padding-left: 10px;
        }

        &:not(:last-child) {
          padding-right: 10px;
        }
      }

      @media @mobile {
        margin: auto;
        padding-bottom: 50px;
      }

      &:before {
        //content: '“';
        font-size: 45px;
        font-family: @baskerville;

        @media @mobile {
          font-size: 26px;
        }
      }

      &-body {
        @media @mobile {
          padding-right: 20px;
        }
      }

      img {
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;

        @media @mobile {
          height: 38px;
          margin: 0;
        }
      }
    }

    &__divider {
      height: 2px;
      background-color: #a5b8ce;
      margin: 54px 0;

      @media @mobile {
        margin: 20px 0;
      }
    }
  }
}

.landing {
  position: relative;
  overflow: hidden;

  h2 {
    @media @desktop {
      font-size: 110px;
      line-height: 120px;
      padding-top: 90px;
    }
    padding-top: 180px;
    font-size: 60px;
    line-height: 60px;
    font-family: @baskervilleRegular;
    color: @eggshell;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.30000001192092896px;
    text-align: center;

    .italic {
      font-family: @baskerville;
    }
  }

  .logo {
    vertical-align: bottom;
  }

  .title {
    padding-top: 1em;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
    padding-bottom: 1.5em;

    @media @mobile {
      padding-top: 0.2em;
      padding-bottom: 1em;
    }

    .company {
      font-family: @baskervilleRegular;
      font-style: normal;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }
  }

  .statement-title {
    margin-top: 2vh;
  }

  .welcome {
    margin: 0 20px;
    position: relative;
    z-index: 1;
    @media @desktop {
      height: 100vh;
    }

    .inner {
      display: flex;
      flex-direction: column;

      align-items: center;
      text-align: center;
      height: 100vh;
      justify-content: center;

      @media @desktop {
      }
    }

    .logo-mobile {
      margin-top: 45px;
      position: absolute;
      z-index: 2;
      margin-right: auto;
      margin-left: auto;
      width: 100%;

      @media @desktop {
        display: none;
      }
    }

    .subtitle {
      color: @eggshell;
      margin-bottom: 2em;
    }

    button {
      margin-top: 20px;
      @media @desktop {
        position: absolute;
        left: 50%;
        margin-left: -22px;
        width: 44px;
        bottom: 100px;
      }
    }
  }

  .background-eye {
    position: absolute;
    z-index: 0;
    background-size: cover !important;

    background: url('assets/landing-hero-mobile.jpg') #000;
    background-position: center !important;

    @media @desktop {
      background: url('assets/landing-hero.jpg') #000;
    }

    height: 120vh;
    width: 100%;
  }

  .discover {
    padding-bottom: 5em;
    @media @desktop {
      padding-top: 2em;
    }

    @media @mobile {
      .h1 {
        margin-top: 1em;
        font-size: 35px;
        line-height: 35px;
      }
    }

    .italic {
      font-family: @baskerville;
      font-size: 24px;
      line-height: 30px;
    }
  }

  .discover__video {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    .adaptive-video {
      border-radius: 30px;
      //height: 520px;
      @media @desktop {
        max-width: 350px;
      }
      width: 100%;
    }
  }

  .discover__content {
    flex: 1;
    margin-top: 0em;

    @media @desktop {
      margin-top: -2.8em;
    }

    p {
      margin-top: 3em;
      margin-bottom: 1.5em;
    }

    .qoute {
      padding-top: 4em;
    }
  }

  .our-process {
    margin-bottom: 4em;

    .title {
      padding-bottom: 1em;
      width: auto;
      @media @desktop {
        text-align: center;
      }
    }

    .progress-item {
      flex: 1;
      padding-top: 2em;
      @media @desktop {
        padding-right: 4em;
      }
      h4.h {
        margin-bottom: 0.2em;

        font-family: @baskerville;
        font-size: 45px;
        line-height: 45px;
      }

      p {
        border-top: 1px solid @love;
        padding-top: 1em;
      }
    }
    .bottom {
      max-width: 920px;
      margin: auto;
      margin-top: 6em;
      display: flex;
      flex-direction: column;
      @media @desktop {
        flex-direction: row;
      }
      .image {
        flex: 1;
        text-align: center;
        margin-bottom: 4em;
      }

      .content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      h3.h3 {
        flex: 0;
        text-align: center;
        line-height: 30px;
        margin-bottom: 1em;
        @media @desktop {
          line-height: 50px;
        }
      }
    }
  }

  .not-alone {
    .header {
      margin: auto;
      max-width: 800px;

      text-align: center;
      margin-top: 2em;
      margin-bottom: 5em;
      .italic {
        font-family: @baskerville;
        font-size: 45px;
        line-height: 50px;
        @media @desktop {
          font-size: 60px;
          line-height: 60px;
        }
      }
    }
  }

  .reviews {
    background: url('assets/paul.jpg') #000;
    background-size: cover;
    padding-top: 1em;
    padding-bottom: 1em;

    .readmore {
      color: @nueblue;
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    @media @desktop {
      .title {
        margin-top: -140px;
        margin-left: 45vw;
      }

      .readmore {
        width: 320px;
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;

        .review {
          flex: 1 1 30%; /*grow | shrink | basis */
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
        }
      }
    }
  }

  .steps {
    margin-top: 4em;
    position: relative;
    z-index: 2;
    color: @eggshell;

    > .title {
      .italic {
        font-family: @baskerville;
      }
    }

    .container {
      flex: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      justify-content: center;
      .step {
        margin-bottom: 2em;
      }

      > .subtitle {
        margin-top: 10vh;
        color: @nueblue;
        text-align: center;
      }
      > .title {
        padding-top: 0.5em;
        text-align: center;
        color: @nueblue;
      }
    }
  }

  .features {
    margin-top: 2em;
    margin-bottom: 4em;
    .carousel__item {
      @media @mobile {
        width: 95%;
      }
    }
  }

  .better-together {
    .layout {
      justify-content: center;
      .title {
        padding-bottom: 1em;
      }
    }
  }

  .plans {
    padding-bottom: 40px;
    color: @nueblue;

    &__image {
      flex: 1;
      img {
        border-radius: 30px;
        margin-top: 4vh;
        @media @desktop {
          max-width: 380px;
        }
        width: 100%;
      }
    }

    .plans__note {
      font-family: @baskerville;

      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }

    &__content {
      flex: 1;
      @media @mobile {
        margin-top: 4vh;
      }

      .title {
        padding: 0;
        margin: 0;
        text-align: left;
      }

      p {
        margin-top: 0.5em;
        margin-bottom: 2em;
        font-family: @gothamBook;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.5px;
      }

      .button {
        width: 255px;
        @media @desktop {
          width: 220px;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;

      .plan {
        margin-bottom: 2em;
      }

      @media @desktop {
        flex-direction: row;
        .plan {
          margin: 2em;
        }
      }

      @media @tablet {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .options {
    color: @nueblue;
    font-size: 18px;
    padding-bottom: 18px;
  }

  .sidenote {
    color: #9c988a;
  }

  .appFooter {
    padding-top: 70px;
    @media @desktop {
      padding-top: 120px;
    }
    z-index: 0;
    margin-top: -40px;
  }

  .testimonials {
    padding-top: 4em;
    padding-bottom: 3em;

    @media @desktop {
      padding-top: 130px;
      padding-bottom: 120px;
    }

    background-color: #c5d3df;
    border-bottom-right-radius: 48px;
    border-bottom-left-radius: 48px;

    .title {
      max-width: 420px;

      @media @desktop {
        position: relative;
        left: -100px;
      }
    }

    &__item {
      font-family: @gothamBook;

      @media @desktop {
        width: 33.33%;

        &:not(:first-child) {
          padding-left: 10px;
        }

        &:not(:last-child) {
          padding-right: 10px;
        }
      }

      @media @mobile {
        margin: auto;
        padding-bottom: 50px;
      }

      &:before {
        //content: '“';
        font-size: 45px;
        font-family: @baskerville;

        @media @mobile {
          font-size: 26px;
        }
      }

      &-body {
        @media @mobile {
          padding-right: 20px;
        }
      }

      img {
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;

        @media @mobile {
          height: 38px;
          margin: 0;
        }
      }
    }

    &__divider {
      height: 2px;
      background-color: #a5b8ce;
      margin: 54px 0;

      @media @mobile {
        margin: 20px 0;
      }
    }
  }
}
