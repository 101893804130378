@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

.footer_container {
    position: absolute;
    top: 85%; 
}

.back_button {
    color: #0D345C;
    width: 50%;
}

.next_button {
    width: 50%;
}

.back_icon_container {
    height: 30px;
}

@media screen and (max-width: @screen-sm) {
    .back_button {
        width: 95%;
    }
    .next_button {
        width: 95%;
    }
    .back_icon_container {
        height: max-content;
    }
}