@import 'styles/responsive.less';
.modal {
  background-color: #052443dd;
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 40px 20px 20px;
  opacity: 0;
  pointer-events: none;
  text-align: center;
}

.modal-enter-done {
  opacity: 1;
  pointer-events: auto;
  .modal-content {
    transform: scale(1);
  }
}
.modal-exit {
  opacity: 0;
  .modal-content {
    transform: scale(0.4);
  }
}

.modal-content {
  width: 100%;
  height: auto;
  max-height: 90%;
  transform: scale(0.4);
  transition: transform 0.3s ease-in-out;
  padding: 34px;
  border-radius: 30px;
  background-color: #f3f3f3;
  max-width: 860px;
  &.bigger {
    max-width: 90vw;
  }
  //max-width: min(960px, 80%);
  
  

  .close-btn {
    width: 54px;
    height: 54px;
    background-color: #0d345c;
    border-radius: 99999px;
    background-image: url('./close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    margin-bottom: 24px;
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
  }

  @media @mobile {
    width: 95%;
  }

  
  &.borderless {
    border-radius: 0px;
    padding: 0;
    width: auto;
    height: auto;
    background-color:transparent;
  }

  &.scrollable {
    overflow: auto;
    scrollbar-width: none;
    padding: 0px 0px 0px;
    border-radius: 3em;
  }
}
