@import 'styles/responsive.less';
@import 'styles/variables.less';

.feature-discovery {
  width: 100%;
  border-radius: 30px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  background: @eggshell;
  color: @nueblue;

  .title {
    font-family: @baskervilleRegular;
    font-size: 45px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
  }

  .feature__subtitle {
    color: @warmblue;
  }

  &__text {
    display: flex;
  }

  &__step {
    margin-right: 30px;
    //styleName: H2;
    font-family: @baskerville;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
  }

  &__innerText {
    display: flex;
    flex-direction: column;
    p {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .carousel__controls {
      justify-content: flex-start;
      margin-bottom: 40px;
    }
  }

  &__name {
    color: @calmblue;
    font-family: @gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-transform: uppercase;
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    @media @mobile {
      height: 550px;
    }
  }

  &__image {
    flex: 1;
    //margin-bottom: 40vh;
    position: relative;

    @media @mobile {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    img {
      width: 40vw;
    }
  }

  &__text {
    flex: 1;
  }

  p {
    //styleName: Body Copy Long Read;
    font-family: @gothamBook;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
    text-align: left;
  }

  @media @desktop {
    width: 100vw;
    padding: 40px;
    margin-left: 0em;
    padding-bottom: 60px;

    .title {
      font-family: @baskervilleRegular;
      font-size: 70px;
      font-weight: 400;
      line-height: 70px;
      text-align: left;
    }

    .subtitle {
      padding-bottom: 30px;
    }

    &__text {
      margin-top: 80px;
      flex-direction: column;
      p {
        margin-top: 15px;
      }
    }

    &__step {
      margin-bottom: 40px;
    }

    &__content {
      display: flex;
      flex-direction: row;
    }

    &__image {
      flex: 1;
      margin-bottom: 0;
      display: flex;

      img {
        height: 800px;
        width: auto;
      }
    }
  }
}
