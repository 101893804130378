@import 'styles/responsive.less';
@import 'styles/variables.less';

.generatedQuestion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3em;

  &__number {
    flex: 0;
    padding-right: 23px;
    min-width: 54px;
  }
  &__title {
    margin-bottom: 20px;
  }

  &__content {
    flex: 1;
  }

  @media @desktop {
    .generatedQuestion__number {
      min-width: 100px;
    }
  }
}
