@import 'styles/responsive.less';
@import 'styles/variables.less';

.stepAccount {
  .section {
    height: 100%;
    min-height: 100vh;
  }

  h1 {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .button {
    margin-right: 1em;
    margin-bottom: 2em;
  }

  .acceptTerms {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .bodycopy2 {
    margin-bottom: 1em;
    a {
      text-decoration: underline;
    }
  }

  @media @desktop {
    .submit.button {
      margin-top: 40px;
      width: 340px;
    }
  }
}
