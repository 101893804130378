@import 'styles/responsive.less';
@import 'styles/variables.less';

.accordion {
  border-bottom: #d2cfc9 1px solid;

  padding-bottom: 24px;
  margin-top: 24px;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 1s ease-in-out;

  &.open {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    p {
      display: block;
      opacity: 1;
    }
  }

  h2 {
    font-family: @baskerville;
    letter-spacing: -0.3px;
    text-transform: lowercase;
    font-size: 28px;
    line-height: 45px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    @media @desktop {
      font-size: 32px;
      line-height: 45px;
    }

    .icon {
      padding-left: 20px;
    }
    svg {
      margin-top: 4px;
      height: 8px;
      width: 12px;
    }
  }

  p {
    font-family: @gothamBook;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.4px;
    opacity: 0;
    display: none;
  }
}
