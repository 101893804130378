@import 'styles/responsive.less';
@import 'styles/variables.less';

.banner {
  background-color: @nueblue;
  color: white;

  font-size: 14px;
  line-height: 20px;

  :global(a.button) {
    margin: 0;
    min-width: 150px;
    flex-shrink: 0;
  }

  .bold {
    font-family: @gotham;
    flex-shrink: 0;
  }
  .normal {
    font-family: @gothamBook;
  }
}

.wrapper {
  position: relative;
  justify-content: center;
  display: inline-flex;

  &.nav {
    z-index: 20;
    top: 30px;

    left: 50%;
    /* Calculates 50% of the element's width, and moves it by that */
    /* amount across the X-axis to the left */
    transform: translateX(-50%);

    @media screen and (max-width: 1250px) {
      top: 120px;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &.inner {
    display: block;
    margin-bottom: 50px;

    @media screen and (min-width: 601px) {
      display: none;
    }
  }
}

.nav {
  .banner {
    display: inline-flex;
    gap: 30px;
    flex-direction: row;
    padding: 10px;
    padding-left: 40px;
    border-radius: 999999px;

    justify-content: space-between;
    align-items: center;
  }
}

.inner {
  .banner {
    flex-direction: column;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    text-align: left;
    max-width: 360px;
  }
  .text {
    margin-bottom: 20px;
  }
}
