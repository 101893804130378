@import 'styles/responsive.less';
@import 'styles/variables.less';

.stepCall {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 600px;
  width: 100%;

  .loader-wrapper .loader {
    margin-top: 100px;
  }

  .callNowForm {
    margin-top: 10vh;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.4px;
    font-family: @gotham;
    //background: #f3f3f3;
    border-radius: 24px;
    padding: 20px;
  }

  .connectForm {
    width: 100%;
    margin-top: 10vh;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.4px;
    font-family: @gotham;

    .wrapper {
      max-width: 400px;
    }

    h2 {
      margin-bottom: 0.5em;
    }
    p {
      margin-bottom: 2em;
    }
    div {
      margin-bottom: 1em;
    }
  }

  > .section > .container {
    max-width: 1100px;
  }

  &__content {
    flex: 1;
    margin-top: 2em;
  }

  &__title {
    padding-left: 20px;
    max-width: 460px;
  }

  .section {
    flex: 1;
  }

  .bodycopylong {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .divider {
    background: @nueblue;
  }

  .stepScheduleAppointment__content .layout > .loader {
    height: 60px;
    width: 60px;
    margin-top: 50px;
  }

  @media @mobile {
    .signupStepContainer__content {
      padding-left: 0;
    }
  }
}
