@import 'styles/variables.less';

.stepper {
  display: flex;
  justify-content: center;

  margin: 0 auto;
  padding: 2em 0;

  &__item {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    &.done {
      .stepper__dot {
        border: 3px solid @nueblue;
        height: 18px;
        width: 18px;
        border-radius: 9px;
      }
    }
  }

  &__line {
    border: 1px solid #f3f3f3;
    height: 0px;
    width: 30px;
  }

  &__dot {
    border: 2px solid @eggshell;
    height: 8px;
    width: 8px;
    border-radius: 4px;
  }
}
