@import '../../styles/variables';

.errorDialog h1 {
  text-transform: uppercase;
  font-family: @gotham;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4000000059604645px;
  margin-bottom: 2em;
}

.errorDialog .dialog__container button {
  margin-top: 2em;
}

.errorDialog .dialog__close {
  .icon {
    width: 16px;
    height: 17px;
  }
}
