@import 'styles/responsive.less';
@import 'styles/variables.less';

.stepCPCall {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 600px;
  width: 100%;

  &__content {
    flex: 1;
    margin-top: 2em;
  }

  &__title {
    padding-left: 20px;
    max-width: 460px;
  }

  .section {
    flex: 1;
  }

  .bodycopylong {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .chilipiper-popup.in-custom-element {
  }

  .chilipiper-popup {
    background: none;
  }

  .chilipiper-popup .chilipiper-popup-window {
    width: 100% !important;
    max-width: 100% !important;
    background: none;
  }

  .chilipiper-popup .chilipiper-popup-window .fabe-book-loading {
    background: none;
  }

  .divider {
    background: @nueblue;
  }

  @media @mobile {
    .signupStepContainer__content {
      padding-left: 0;
    }
  }
}
