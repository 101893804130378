@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

.page-test {
  position: relative;
  background: @eggshell;

  .hero-background {
    position: absolute;
    z-index: 0;
    background-size: cover !important;
    background: url('../FAQ/images/hero.jpg') @calmblue center;
    height: 100vh;

    @media @desktop {
      background: url('../FAQ/images/hero.jpg') center @calmblue;
      height: 100vh;
    }
    width: 100%;
  }

  .hero {
    position: relative;
    z-index: 1;
    color: @eggshell;
    height: 600px;
    @media desktop {
      height: 650px;
    }
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: center;

    h2 {
      font-family: @baskerville;
      line-height: 30px;
      font-size: 32px;
      max-width: 750px;
      text-align: center;

      .special {
        display: inline-block;
        font-family: @gothamBook;
        white-space: no-wrap;
        font-size: 22px;
        line-height: 32px;
        @media @desktop {
          font-size: 35px;
          line-height: 60px;
          letter-spacing: -1.3px;
        }
      }

      @media @desktop {
        line-height: 60px;
        font-size: 50px;
      }
    }
    h1 {
      font-family: @gotham;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
    }
  }
  .section {
    margin-top: -50px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: -50px;

    h3 {
      font-size: 40px;
      line-height: 44px;
      font-family: @baskerville;
      margin-bottom: 64px;

      letter-spacing: -0.3px;
      @media @desktop {
        font-size: 64px;
        line-height: 64px;
      }
    }

    .container {
      padding: 0 40px;
    }

    .reach-out {
      margin-top: 16px;
      padding: 16px 0;
      font-family: @gothamBook;
      font-size: 16px;
      line-height: 26px;
      a {
        text-decoration: underline;
      }
    }
  }
}
