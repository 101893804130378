@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

.step {
  width: 100%;
  min-height: 420px;
  border-radius: 30px;
  padding: 20px;
  position: relative;

  .button.transparent {
    //styleName: CTA;
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: @eggshell;
  }

  .title {
    padding: 0;
    width: auto;
    text-align: left;
  }

  .subtitle {
    text-align: left;
    padding-bottom: 10px;
    padding-top: 40px;
  }

  p {
    margin-top: 41px;

    text-align: left;
    transition: height 0.2s @easeInOutCubic;
    overflow: hidden;
  }

  &__top {
    flex: 1;
    padding-top: 1em;
  }

  &__center {

  }

  &__bottom {
    flex: 0;
  }

  &__textWrapper {
    position: relative;
  }

  &__textWrapper {
    &:after {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      content: '';
      opacity: 0;
      background: linear-gradient(
        180deg,
        rgba(111, 126, 124, 0) 0,
        #6f7e7c 94.32%
      );
      pointer-events: none; /* so the text is still selectable */
      transition: opacity 0.2s linear;
    }

    &--fade:after {
      opacity: 1;
    }
  }

  &.bg-love {
    color: @nueblue;
    .button {
      color: @nueblue;
      border-color: @nueblue;
    }
    .step__textWrapper:after {
      background: linear-gradient(
        180deg,
        rgba(232, 181, 166, 0) 0,
        @love 94.32%
      );
    }
  }

  &.bg-nueblue {
    .step__textWrapper:after {
      background: linear-gradient(
        180deg,
        rgba(13, 52, 92, 0) 0,
        @nueblue 94.32%
      );
    }
  }

  &.bg-changeyellow {
    color: @nueblue;
    .button {
      color: @nueblue;
      border-color: @nueblue;
    }
    .step__textWrapper:after {
      background: linear-gradient(
        180deg,
        rgba(244, 215, 137, 0) 0,
        @changeyellow 94.32%
      );
    }
  }

  @media @desktop {
    width: 460px;
    min-height: 450px;
    padding: 40px;
    margin: 0.5em;

    .h2 {
      font-size: 70px;
      font-weight: 400;
      line-height: 70px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }

    .subtitle {
      padding-bottom: 30px;
    }

    .overlay {
      height: 80%;
    }
  }
}

/*.smaller {
  height: 300px !important; 
  width: 400px !important;
  font-size: 2em;
  font-family: @gothamBook;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media @mobile {
    font-size: 1.5em;
    min-height: 300px;
  }
}*/

.stepDialog {
  color: @eggshell;
  .dialog__container {
    background: @eggshell;
    background-image: url('assets/eggshell.png');
    padding: 0;
  }

  .stepDialog__top {
    padding: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    &.bg-love {
      color: @nueblue;
    }

    &.bg-changeyellow {
      color: @nueblue !important;
    }
  }

  .h2 {
    font-family: @baskerville;
    font-size: 45px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
    margin-right: 20px;
    padding-bottom: 25px;
  }

  .subtitle {
    text-align: left;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  &__content {
    padding: 20px;
    padding-top: 40px;
    color: @nueblue;
    //styleName: Body Copy Long Read;
    font-family: Gotham;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
    text-align: left;
  }

  @media @desktop {
    .stepDialog__top {
      padding: 40px;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    .h2 {
      font-family: Baskerville;
      font-size: 70px;
      font-weight: 400;
      line-height: 70px;
      letter-spacing: -0.30000001192092896px;
    }

    &__content {
      padding: 40px;
    }
  }
}
