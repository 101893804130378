@import 'styles/responsive.less';
@import 'styles/variables.less';

.stepQuestions {
  display: flex;
  flex-direction: column;

  color: @nueblue;

  .section {
    min-height: 100vh;
  }

  .signupStepContainer__content {
    padding-left: 0;
  }

  .stepper {
    margin: 2em auto;
  }

  &__content {
    margin-top: 3em;
  }

  &__submit {
    display: flex;
    div {
      width: 63px;
    }
    margin-bottom: 3em;
  }

  &__notes {
    display: flex;
    .spacer {
      width: 63px;
    }
    .text {
      width: 100%;
    }
    margin-bottom: 3em;
  }

  li {
    list-style: disc;
    margin-left: 10px;
    padding-top: 1em;

    //styleName: Body Copy 2;
    font-family: @gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
  }

  @media @desktop {
    &__submit {
      div {
        width: 100px;
      }
      .button {
        width: 340px;
      }
    }

    &__notes {
      .spacer {
        width: 100px;
      }
      .text {
        width: 340px;
      }
    }

    &__content {
      margin-top: 220px;
    }
  }
}
