@import 'styles/responsive.less';
@import 'styles/variables.less';

.readtime {
  font-family: @gotham;
  letter-spacing: -0.4px;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  color: #9f9d98;
}
