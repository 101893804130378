@import 'styles/responsive.less';

.container {
  width: 100%;
  padding: 0 20px;
  @media @desktop {
    max-width: 1469px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
  }

  @media @mobile {
    width: 100%;
  }
}
