@import 'styles/responsive.less';
@import 'styles/variables.less';

.birthdateInput {
  width: 100%;

  margin-right: 1em;

  @media @desktop {
    width: 340px;
  }

  &.error {
    .birthdateInput__error {
      visibility: visible;
    }
    label {
      color: red;
    }
  }

  &__error,
  label {
    font-family: @gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
  }

  &__error {
    color: red;
    height: 18px;
    padding-left: 20px;
    padding-bottom: 5px;
    visibility: hidden;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px 10px 20px;

    margin-bottom: 10px;

    background: @eggshell;
    opacity: 0.8;
    border-radius: 10px;
  }

  &__inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 21px;
  }

  &.hasValue {
    opacity: 1;
  }

  label {
    font-family: @gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
  }

  &__error {
    color: red;
  }

  .seperate {
    color: @warmgrey;
    margin: 0 3px 0 1px;
  }

  input {
    font-family: @gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    padding: 0;

    &[minlength='2'] {
      width: 1.8em;
    }

    &[minlength='4'] {
      width: 3em;
    }

    &::placeholder {
      text-transform: uppercase;
      color: #0d345c;
    }
  }
}
