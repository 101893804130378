.icon.arrow-left,
.icon.arrow-right {
  width: 9px;
  height: 12px;
}

.icon.tailed-arrow-left,
.icon.tailed-arrow-right {
  width: 18px;
  
}

.icon.arrow-up {
  svg {
    transform: rotate(180deg);
  }
}

.icon {
  display: inline-block;
}

.icon .externalArrow {
  width: 18px;
}
