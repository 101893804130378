@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

.in_news__item {
  margin-top: 1em;
  font-family: @gothamBook;

  @media @desktop {
    width: 33.33%;

    &:not(:first-child) {
      padding-left: 10px;
    }

    &:not(:last-child) {
      padding-right: 10px;
    }
  }

  @media @mobile {
    margin: auto;
    padding-bottom: 50px;
  }

  &:before {
    //content: '“';
    font-size: 45px;
    font-family: @baskerville;

    @media @mobile {
      font-size: 26px;
    }
  }

  &-body {
    @media @mobile {
      padding-right: 20px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    height: auto;
    max-width: 100%;

    @media @mobile {
      height: 38px;
      margin: 0;
    }
  }

  .link_wrapper {
    text-align: center;
    margin-top: 2em;
  }

  :global .link {
    line-height: 15.6px;
    font-size: 13px;
    padding-bottom: 6px;
    letter-spacing: -0.4px;
    border-bottom: 1.5px solid @nueblue;
    font-family: @gotham;
    display: inline-block;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

    .icon {
      height: 12px;
      width: 12px;
    }
  }
}
