@import 'styles/responsive.less';
@import 'styles/variables.less';

.carousel-items {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  z-index: 4;
  padding-bottom: 2em;
  white-space: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  align-items: flex-start;
  width: 100%;

  @media @desktop {
    //display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.carousel {
  ::-webkit-scrollbar {
    display: none;
  }
  &.compact {
    .carousel__item {
      width: 100%;
      padding: 0;
      margin-left: 40px;
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;

    scroll-snap-align: start;
    white-space: normal;
    width: 75%;
    padding: 0 10px;
    // max-width: 460px;
  }

  &__controls {
    display: none !important;

    &.top {
      margin-bottom: 2em;
      display: flex;
      padding-right: 40px;
    }

    .button {
      background: #fefaf7;
      border: 1.5px solid #fefaf7;
      color: @nueblue;
      &.disabled {
        background: transparent !important;
      }
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin-left: 2px;
    }
  }

  @media @desktop {
    &.length-4 {
      // max-width: 1700px;
      margin: 0 auto;

      .carousel__controls {
        display: none;
      }

      .carousel__item {
        display: inline-flex;
      }
      .carousel__item .plan {
        width: 580px !important;
      }
    }
    &__controls.top {
      display: flex;
    }
  }
}
