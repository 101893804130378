@import 'styles/variables.less';
@import 'styles/responsive.less';

.wrapper {
  padding: 100px 0;
  height: 600px;
  background-color: #888;
  background-size: cover;
  background-position: top center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  padding: 0 30px 30px 30px;

  max-width: 80%;
  @media @desktop {
    max-width: 720px;
  }

  h1 {
    font-family: @baskerville;
    font-size: 30px;
    line-height: 32px;
    margin-top: 0px;
    letter-spacing: -0.3px;

    span {
      font-family: @baskervilleRegular;
    }

    @media @desktop {
      font-size: 50px;
      line-height: 60px;
    }
  }

  h2 {
    font-family: @gotham;
    font-size: 16px;
    line-height: 26px;
  }
}
