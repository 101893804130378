@import './variables.less';

:root {
  --color-eggshell: @eggshell;
  --color-warmgrey: @warmgrey;
  --color-warmblue: @warmblue; // deprecate?
  --color-calmblue: @calmblue;
  --color-nueblue: @nueblue;
  --color-nueblack: @nueblack;
  --color-prosperity: @prosperity;
  --color-love: @love;
  --color-changeyellow: @changeyellow;
  --color-wellness: @wellness;
  --color-sleep: @sleep;
  --color-error: @error;
}

body {
  font-family: @gotham;
  color: @nueblue;
  // overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

.grecaptcha-badge {
  visibility: hidden;
}

.offscreen{
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}