@import 'styles/responsive.less';
@import 'styles/variables.less';

.planPrice {
  color: @eggshell;

  display: flex;
  flex-direction: row;

  &__item {
    margin-right: 1em;
    display: flex;
    flex-direction: row;
  }

  &__divider {
    color: @eggshell;
    width: 2px;
    height: 30px;
    border-radius: 2px;
    display: inline-block;
    margin-left: 2em;
    margin-top: 22px;
  }

  &__currency {
    vertical-align: top;
  }

  /*.total .planPrice__type { //COMMENTED ON WEIRD BEHAVIOUR IN MOBILE
    //display: inline;
  }*/

  &__type {
    display: block;
    color: @nueblue;
    margin-left: 10px;

    font-family: @gotham;
    font-size: 14px;
    font-style: normal;

    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
  }

  &__amount {
    color: @eggshell;
    font-family: @baskervilleRegular;
    font-size: 70px;
    font-style: italic;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: -7px;
  }
}
