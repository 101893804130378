@import 'styles/easing.less';
@import 'styles/variables.less';
@import 'styles/responsive.less';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(10%);
  }
  to {
    transform: translateY(0);
  }
}

.dialog__background {
  animation: fadeIn 0.3s linear;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 11, 30, 0.8);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @media @desktop {
    justify-content: center;
  }
}

.dialog__close.button {
  margin-top: 20px;
  position: relative;
  margin-bottom: 2em;
}

.dialog__container {
  z-index: 11;
  background-color: @wellness;
  position: relative;
  animation: slideUp 0.4s @easeOutCubic 0.1s both;
  transform: translateY(10%);

  border-radius: 24px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin: 0 16px;
  padding: 16px;

  @media @desktop {
    border-radius: 30px;
    max-width: 939px;
    width: 939px;
    padding-top: 40px;
    margin: 0;
    align-items: flex-start;
  }
}
