@import 'styles/variables.less';
@import 'styles/responsive.less';
@import 'styles/easing.less';

.bannerCTA {
  border-radius: 30px;
  margin-top: 80px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 100px;

  @media @desktop {
    flex-direction: row;
    padding: 40px;
    gap: 45px;
  }

  img {
    @media @tablet {
      display: none;
    }
    height: 150px;

    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    @media @desktop {
      width: auto;
      margin: 0;
    }
  }

  h3.title {
    font-weight: 400;
    font-size: 27px;
    line-height: 40px;
    font-family: @gothamBook;
    width: 100%;
    letter-spacing: -1.3px;
    flex-basis: 50%;
    span {
      font-family: @baskerville;
      font-size: 40px;
      letter-spacing: -0.3px;
      line-height: 40px;
    }

    flex: 1;
    flex-grow: 2;

    @media @desktop {
      font-size: 35px;
      line-height: 52px;
      span {
        font-size: 50px;
        letter-spacing: -0.3px;
        line-height: 44px;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1.5px;
    margin-bottom: 0px;
    margin: 0;
    background: rgba(255, 255, 255, 0.2);
    @media @desktop {
      min-width: 1.5px;
      width: 1.5px;
      height: 140px;
    }
  }

  .cta-wrapper {
    padding: 0 10px;
    width: 100%;
    flex: 1;
    @media @mobile {
      padding: 0;
      width: 100%;
    }
    p {
      .bold {
        font-family: @gotham;
      }
      font-family: @gothamBook;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.4px;
      padding-bottom: 22px;
    }

    .button {
      margin: 0;

      @media @mobile {
        width: 100%;
      }
    }
  }
}
