@import 'styles/variables.less';
@import 'styles/responsive.less';
@import 'styles/easing.less';

.journal-header {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  //border-top: 2px solid #fefaf7;
  padding-top: 35px;
  margin-bottom: 41px;

  justify-content: space-between;
  align-items: center;

  @media @desktop {
    margin-bottom: 64px;
  }

  .back {
    @media @desktop {
      display: block;
    }
    @media @mobile {
      width: 20px;
      height: 20px;
    }

  }

  &__search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #f2ede8;
    opacity: 0.8;
    z-index: 100;
  }

  .button.back {
    @media @desktop {
      display: flex;
      border: 1.5px solid #fcf7f3;
    }

    margin: 0;

    .icon {
      color: @nueblue;
      margin: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__title {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: -0.3px;
    font-family: @baskerville;
    margin-left: 5px;
    @media @desktop {
      font-size: 70px;
      line-height: 52px;
      margin-left: 12px;
    }
  }

  &__categories {
    display: flex;
    align-items: baseline;
    &__item {
      display: inline-block;

      font-family: @gotham;
      letter-spacing: -0.3px;
      font-size: 13px;
      line-height: 16px;
      margin-right: 24px;
      &.active {
        padding-bottom: 3px;
        border-bottom: 1.5px solid #0d345c;
      }
    }
    .journal-tag.heal-from-home {
      margin-left: 0;
      margin-right: 24px;
    }
    .heal-from-home {
      margin-left: 12px;
    }
  }

  &__search {
    margin: 0;
    width: fit-content;
    .text {
      display: none;
    }

    @media @desktop {
      .text {
        display: block;
        margin-left: 5px;
      }
      .icon {
        margin-left: 10px;
      }
    }

    &:hover {
      background: #fefaf7;
    }

    border: 1.5px solid #fefaf7;
    border-radius: 100px;
    background: transparent;
    color: @nueblue;
    height: 45px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    cursor: pointer;

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  &__select {
    margin-right: 10px;
    .select__container {
      background: #f2ede8;
      border: 1.5px solid #fefaf7;
      border-radius: 100px;
      align-items: center;
      display: flex;
      margin: 0;
      height: 45px;
      flex-direction: row;
      padding: 0px 10px;
      padding-top: 4px;
    }

    /* NueBlue */

    select {
      font-family: @gotham;
      font-size: 12px;
      letter-spacing: -0.4px;
      color: #0d345c;
      padding: 0;
      width: auto;
      margin: 0;
    }
    .icon {
      margin-left: 5px;
      width: 5px;
      margin-top: -5px;
    }
  }
}
