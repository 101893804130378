@import 'styles/responsive.less';
@import 'styles/variables.less';
.planPriceVariantUpgrade{
  .planPriceVariant {
    color: @eggshell;
  
    display: flex;
    flex-direction: row;
  
    &__item {
      margin-right: 1em;
      display: flex;
      flex-direction: row;
  
      &.total {
        .planPriceVariant__amount {
          font-size: 40px;
        }
      }
    }
  
    &__divider {
      background: @wellness;
      width: 2px;
      height: 50px;
      display: inline-block;
      margin-left: 2em;
      margin-top: 10px;
    }
  
    &__currency {
      color: @wellness;
      margin-top: -1em;
    }
  
    .total .planPrice__type {
      display: inline;
    }
  
    &__type {
      display: block;
      color: @wellness;
      margin-left: 10px;
  
      font-family: @gotham;
      font-size: 14px;
      font-style: normal;
  
      line-height: 20px;
    }
  
    &__amount {
      color: @eggshell;
      font-family: @baskervilleRegular;
      font-size: 60px;
      font-style: italic;
      font-weight: 350;
      line-height: 40px;
      letter-spacing: -2px;
    }
  }
  
}
