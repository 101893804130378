.video-stream {
  position: relative;
  background-color: #000;
  height: 100%;
  border-radius: 20px;

  &__error {
    position: absolute;
    z-index: 1;
    top: 15px;
    left: 15px;
    background: #000;
    color: #e20000;
    font-weight: bold;
    padding: 0.3em 0.9em;
    border-radius: 3px;
  }

  &__video {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;

    &--disabled {
      opacity: 0.5;
    }
  }
}
