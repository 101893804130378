@import '../../styles/variables';
@import '../../styles/responsive';

.leadDialog {
  h1 {
    text-transform: uppercase;
    font-family: @gotham;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4000000059604645px;
    margin-bottom: 20px;
  }

  .button {
    margin-top: 20px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media @desktop {
    .dialog__container {
      padding: 40px;
      p {
        margin-top: 2em;
        margin-bottom: 20px;
      }
      .select,
      .input,
      .button {
        width: 292px;
      }
    }
  }
}
