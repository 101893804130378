@spacing-xxs:   4px;
@spacing-xs:   8px;
@spacing-s:    16px;
@spacing-m:    32px;
@spacing-l:   64px;
@spacing-xl:  128px;
@spacing-xxl: 256px;

.spacer {
  &--xxs { height: @spacing-xxs; }
  &--xs  { height: @spacing-xs; }
  &--s   { height: @spacing-s;  }
  &--m   { height: @spacing-m;  }
  &--l   { height: @spacing-l;  }
  &--xl  { height: @spacing-xl; }
  &--xxl  { height: @spacing-xxl; }
}
