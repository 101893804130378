@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';


.yellow_background {
    background-color: #FFD886;
    background-size: cover;
    background-position: top;
    min-height: 100vh;
}

.banner_container {
    width: 40vw;
}

.statement_subtitle {
    margin-top: 1em;
}

@media screen and (max-width: @screen-sm) {
    .banner_container {
        width: 85vw;
    }
}