@import './responsive.less';
@import './variables.less';

.title {
  font-family: @baskervilleRegular;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.30000001192092896px;

  @media @desktop {
    font-size: 50px;
    font-weight: 400;
    line-height: px;
    letter-spacing: -0.30000001192092896px;
  }
}

.h1 {
  font-family: @baskervilleRegular;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.30000001192092896px;

  @media @desktop {
    //styleName: H1;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: -0.30000001192092896px;
  }
}

.h2,
.statement-title {
  //styleName: H2;
  font-family: @baskervilleRegular;
  font-size: 26px;
  font-style: italic;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.30000001192092896px;
}

.bodycopy1 {
  font-family: @gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.4000000059604645px;
}

.bodycopy2 {
  font-family: @gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4000000059604645px;
}

.bodycopylong {
  //styleName: Body Copy Long Read;
  font-family: @gotham;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.bodytext {
  font-family: @gothamBook;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.company-text {
  font-family: @baskerville;
  font-size: 45px;
  line-height: 45px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.30000001192092896px;

  @media @desktop {
    font-size: 65px;
    line-height: 70px;
  }
}

.italic {
  font-style: italic;
}

.subtitle {
  font-family: @gotham;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4000000059604645px;
  text-transform: uppercase;
}

.cta {
  font-family: @gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.divider {
  height: 2px;
  background: @eggshell;
  border-radius: 2px;
  margin: 2em 0;
}

.only-desktop {
  display: none !important;

  @media @desktop {
    display: initial !important;
  }
}

.only-mobile {
  display: none;
  @media @mobile {
    display: initial;
  }
}

.left-space {
  @media @mobile {
    padding-left: 14vw;
  }
}

.bg-eggshell {
  background: @eggshell;
}

.bg-warmgrey,
.bg-calmblue {
  background: @warmgrey;
}

.bg-nueblue {
  background: @nueblue;
}

.bg-nueblack {
  background: @nueblack;
}

.bg-prosperity {
  background: @prosperity;
}

.bg-love {
  background: @love;
}

.bg-change,
.bg-changeyellow {
  background: @changeyellow;
}
.bg-wellness {
  background: @wellness;
}
.bg-sleep {
  background: @sleep;
}

.calmblue {
  color: @calmblue;
}

.warmblue {
  color: @warmblue;
}

.text-container {
  max-width: 480px;
}

.book {
  font-family: @gothamBook;
}

b {
  font-family: @gotham;
}
