@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';

.background {
  background-color: #EFEBE6;
  background-size: cover;
  background-position: top;
  //min-height: 90vh; This was used for leave space for progress bar
  min-height: 100vh;
}

.blue_background {
  background-color: #0D345C;
  background-size: cover;
  background-position: top;
  min-height: 100vh;
  overflow-y: scroll;
}

.title_container {
  width: 60vw;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.banner_container {
  width: 60vw;
}

.question_container {
  width: 60vw;
  height: 90vh;
}

.question_title_container {
  margin: auto;
  margin-bottom: 2em;
  width: 60vw;
}

.question_option_container {
  width: max-content;
  margin: auto;
}

.form_option_label {
  font-family: 'Montserrat';
  font-style: normal;
}

.question_option_container {
  text-align: center;
}

.label {
    font-family: 'Baskerville';
    font-style: normal;
    text-align: center;
}

.start_upper_title {
  font-style: italic;
  font-size: 1.5em;
}

.question_upper_title {
  font-style: italic;
  font-size: 1.5em;
}

.start_title {
  font-size: 2.5em;
}

.state_title {
  font-size: 2.5em;
  padding-bottom: 0.5em;
}

.start_subtitle {
  font-size: 2em;
}

.start_sub_title {
  padding-top: 2em;
  font-size: 1.2em;
  line-height: 1em;
  text-align: justify;
  text-align-last: center;
}

.question_title {
  font-size: 2em;
}

.thanks_title {
  font-size: 2em;
  margin-top: 1.5em;
}

.thanks_bodycopy {
  margin-top: 0.5em;
  font-size: 1.5em;
}

.question_subtitle {
  font-size: 1.5em;
}

.blue {
  color: #0D345C;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 10px !important;
}

.MuiTextField-root {
  border-radius: 10px;
  height: 60px;
}

.out_of_state_statement {
  font-size: 2em;
}

.white {
  color: #EFEBE6;
}

h1 {
  &.label {
    line-height: auto;
    font-weight: 400;
  }
}

h1 {
  &.statement_title {
    font-size: 48px;
  }
}

h2 {
  &.statement_subtitle {
    font-size: 30px;
    font-style: italic;
  }
}

h2 {
  &.label {
    font-weight: 400;
    line-height: 34px;
  }
}

h1 {
  &.not_qualified_statement {
    font-size: 2em;
    padding-top: 1em;
  }
}

a {
  &.not_qualified_statement {
    font-size: 2em;
    color: #FFD886;
    font-family: 'Baskerville';
  }
}

.started_button_container {
  text-align: center;
}

.started_button {
  margin-top: 2em;
  width: 20em;
  font-family: 'Montserrat';
  font-style: normal;
}

.date_state_container {
  max-width: 30vw;
  margin: auto;
  margin-top: 3em;
}

.bodycopy {
  margin-top: 2em;
  margin-bottom: 2em;
  font-family: 'Baskerville';
  a {
    font-size: 1000;
  }
}

.schedule_button {
  width: 100%;
  margin-top: 2em;
}

p {
  &.caveat_start {
    margin-top: 1em;
    font-family: 'Baskerville';
  }
}

@media screen and (max-width: @screen-sm) {
  h1 {
    &.label {
      font-weight: 400;
    }
  }

  h2 {
    &.label {
      font-weight: 400;
    }
  }

  .state_title {
    font-size: 1.6em;
    line-height: 1em;
    padding-bottom: 0.5em;
    text-align: center;
    word-spacing: -2px;
  }

  .start_title {
    font-size: 1.6em;
    line-height: 1em;
    //text-align: justify;
    text-align-last: center;
  }

  .start_sub_title {
    padding-top: 2em;
    font-size: 1em;
    line-height: 1em;
    text-align: justify;
    text-align-last: center;
  }

  .title_container {
    width: 80vw;
  }

  .banner_title_container {
    width: 80vw;
  }

  .date_state_container {
    max-width: 90vw;
  }

  .question_title_container {
    margin: auto;
    margin-bottom: 1.5em;
    width: 90%;
  }

  .out_of_state_statement {
    font-size: 1.3em;
    line-height: normal !important;
  }

  .question_title {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    text-align: justify;
    text-align-last: center;
    word-spacing: -3px;
  }

  .question_container {
    width: 80vw;
  }

  .question_option_container {
    max-width: 100%;
    max-height: 45vh;
    overflow-y: scroll;
    margin: 0;
  }

  .question_option_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .question_upper_title {
    font-size: 1em;
    margin-bottom: 1em;
    text-align: justify;
    text-align-last: center;
  }

  .question_subtitle {
    font-size: 1em;
    text-align: justify;
    text-align-last: center;
  }


  h1 {
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
  }

  h2 {
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
  }


  h1 {
    &.statement_title {
      font-size: 2em;
      text-align: justify;
    }
  }

  h2 {
    &.statement_subtitle {
      font-size: 1.5em;
      text-align: justify;
      line-height: 1.2em;
    }
  }

  .form_option_label {
    text-overflow: wrap;
    margin-bottom: 0.5em;
  }


  h1 {
    &.not_qualified_statement {
      font-size: 1.5em;
      text-align: justify;
    }
  }

  a {
    &.not_qualified_statement {
      font-size: 1.5em;
    }
  }
}