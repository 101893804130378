@import 'styles/responsive.less';
@import 'styles/variables.less';

.stepConfirm {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 600px;
  width: 100%;

  .divider {
    border-color: @eggshell;
  }

  .planSection {
    margin-bottom: 2em;
  }

  &__title {
    color: @nueblue;
    margin-top: 80px;
    margin-bottom: 1em;
  }

  .planPrice__currency,
  .planPrice__amount {
    color: @nueblue;
  }

  .header {
    flex: 0;
    padding: 20px;
    z-index: 0;

    width: 100%;
  }

  .button.submit {
    margin-top: 2em;
  }

  .section {
    flex: 1;
    p {
      margin-bottom: 1em;
    }
  }

  .bodycopy3 {
    font-size: 0.7em;
  }

  .confirm_list_items {
    font-family: Gotham,sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 1em;
    letter-spacing: -.5px;
    text-align: left;
    padding-right: 3em;
    li {
      list-style-type: disc;
      margin-bottom: 0.5em;
    }
  }

  @media @desktop {
    .planSection {
      margin-top: 142px;
    }
    .button.submit {
      width: 340px;
    }
  }
}
