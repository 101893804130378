@import 'styles/responsive.less';
@import 'styles/variables.less';

.footer {
  background: @nueblue;
  color: @wellness;

  .footer__container {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 64px;
    padding-bottom: 2em;
    justify-content: space-between;

    @media @desktop {
      flex-direction: row;
      padding-top: 100px;
    }
  }

  &__final {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: @calmblue;
    @media @desktop {
      flex-direction: row;
    }

    .links {
      @media @mobile {
        width: 100%;
        justify-content: space-between;
      }

      flex: 0.4;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.4px;
      font-family: @gothamBook;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      max-width: 449px;

      a {
        padding-bottom: 4px;
        border-bottom: 1.5px solid #4b79aa;
      }
    }
  }

  .column.logo {
    flex: 0.2;
    margin-right: 3em;
    svg {
      height: 65px;
      color: @wellness;
    }
    margin-bottom: 1em;
  }

  .column {
    @media @mobile {
      width: 100%;
    }

    p {
      margin-top: 18px;
      font-family: @gothamBook;
      line-height: 16.8px;
      font-size: 12px;
    }
  }

  .column.links {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding-bottom: 40px;
    display: flex;
    flex: 1;

    @media @desktop {
      padding-bottom: inherit;
      flex-direction: row;
    }

    .column {
      width: 100%;
      margin-right: 65px;

      @media @tablet {
        margin-right: 25px;
      }

      @media @desktop {
        min-width: 130px;
        max-width: 275px;
      }
    }

    a {
      font-family: @baskerville;
      display: block;
      font-size: 40px;
      line-height: 45px;
      padding-bottom: 15px;
      border-bottom: 1.5px solid #164a7e;
    }
  }

  .column.last {
    margin-top: 1em;

    p {
      font-weight: normal;
      line-height: 19px;
      font-size: 16px;
      letter-spacing: -0.4px;
      margin-bottom: 30px;
    }

    input {
      width: 100%;
      max-width: 300px;
      @media @desktop {
        width: 274px;
      }
      @media @tablet {
        width: 200px;
      }
      line-height: initial;
      margin: 0 0 0 0;
      padding: 14px 20px 14px 20px;
      border: 1.5px solid #164a7e;
      border-radius: 100px;
      background-color: transparent;
      box-sizing: border-box;
      color: @wellness;
    }
    ::placeholder {
      color: @wellness;
      opacity: 1;
    }

    button {
      margin-right: 10px;
      margin-top: 10px;
      padding: 14px 20px 14px 20px;
    }
  }

  .footer__bottom {
    .certs {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2.5em;
      img {
        width: 100px;
        margin-right: 1em;
      }
    }
  }

  .socialmedia {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
    padding-bottom: 2em;
    max-width: 250px;
    align-items: center;

    .twitter {
      height: 20px;
      width: 25px;
    }
    .facebook {
      height: 20px;
      width: 20px;
    }
    .linked {
      height: 21px;
      width: 20px;
    }
    .instagram {
      height: 19px;
      width: 20px;
    }
  }

  .rights {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.4px;
    font-family: @gothamBook;
    flex: 0.4;
    margin-bottom: 2em;
  }

  .socialmedia {
    @media @mobile {
      width: 100%;
    }
    flex: 0.2;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .icon svg {
      color: @calmblue;
      height: 21px;
    }
  }

  .disclaimer {
    font-family: @gothamBook;
    line-height: 1.2;
    font-size: 14px;
    flex: 1;
  }
}
