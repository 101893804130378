.loadMore {
  width: 162px;
  &.loading {
    background: #f2ede8;
    border: 1.5px solid #d2cfc9;
    padding: 7px;
  }

  .icon.plus {
    width: 17px;
  }

  .loader {
    height: 31px;
    width: 31px;
    margin: auto !important;
    border: 2px solid #0d345c;
  }
}
