@import 'styles/responsive.less';
@import 'styles/variables.less';

.container.provider-info {
  max-width: 1280px;
  padding-top: 100px;
  padding-bottom: 130px;

  svg.network-logo {
    height: 74px;
    margin-bottom: 26px;
  }
  h2.header {
    font-family: @baskervilleRegular;
    font-size: 45px;
    letter-spacing: -0.3px;
    margin-bottom: 60px;
    text-align: center;

    @media @desktop {
      font-size: 50px;
      line-height: 1;
    }
  }

  .two-sections {
    display: flex;
    flex-direction: column;

    @media @desktop {
      flex-direction: row;
      justify-content: space-between;
      gap: 60px;
    }
  }

  .info-side {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60%;

    a {
      text-decoration: underline;
    }

    a.no-underline {
      text-decoration: none;
    }

    p.description {
      font-family: @gothamBook;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 40px;
    }

    .network-info {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      img {
        width: 72px;
        height: 72px;
        margin-right: 15px;
        border-radius: 10px;
        object-fit: contain;
        @media @desktop {
          width: 100px;
          height: 100px;
          margin-right: 25px;
        }
      }

      .info {
        .name {
          font-size: 18px;
          margin-bottom: 10px;
          @media @desktop {
            font-size: 24px;
          }
        }
        div.address {
          margin-bottom: 30px;
        }
        .address {
          font-size: 14px;
          font-family: @gothamBook;
          margin-bottom: 12px;
          @media @desktop {
            font-size: 20px;
          }
        }
      }
    }

    p.bio {
      font-family: @gothamBook;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 40px;
    }

    .small-info-block {
      margin-bottom: 20px;
      .title,
      .text {
        font-family: @gotham;
        font-size: 18px;
        line-height: 30px;
      }
      .text {
        font-family: @gothamBook;
      }
    }

    .button {
      margin-top: 30px;
    }
  }

  .map-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 40%;
    .map-image {
      border-radius: 20px;
      width: 100%;
      min-height: 85vw;
      object-fit: cover;

      @media @desktop {
        min-height: 400px;
      }
    }
  }

  .extra-info {
    margin-top: 20px;
    p {
      font-family: @gothamBook;
      font-size: 18px;
      line-height: 30px;
      //margin-bottom: 40px;
      a {
        font-family: @gotham;
      }
    }
  }
}
.our-process {
  .extra-info {
    margin-top: 40px;
    margin-bottom: 80px;
    p {
      font-family: @gothamBook;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 40px;
      a {
        font-family: @gotham;
      }
    }
  }
}
