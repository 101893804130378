@import 'styles/responsive.less';
@import 'styles/variables.less';

@keyframes buttonLoading {
  0% {
    opacity: 0.2;
    background: @eggshell;
  }
  20% {
    opacity: 1;
    background: @eggshell;
  }
  100% {
    opacity: 0.2;
    background: @nueblack;
  }
}

.sticky-button {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  user-select: none;

  background: @nueblue;
  border-radius: 24px 24px 0 0;

  height: 3.5em;
  color: @eggshell;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: 100%;
  z-index: 2;

  &.disabled {
    color: @calmblue;
  }

  .loading {
    .dot {
      display: inline-block;
      font-size: 50px;
      animation-name: buttonLoading;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      height: 8px;
      background: @nueblue;
      width: 8px;
      border-radius: 8px;
      margin-right: 10px;
    }

    span:nth-child(2) {
      animation-delay: 0.2s;
    }
    span:nth-child(3) {
      animation-delay: 0.4s;
    }
    span:nth-child(4) {
      animation-delay: 0.6s;
    }
    span:nth-child(5) {
      animation-delay: 0.8s;
    }
  }
}
