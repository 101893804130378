@import 'styles/variables.less';
@import 'styles/responsive.less';
@import 'styles/easing.less';

.top-nav-variant {
  z-index: 20;
  width: 100%;
  top: 0;
  position: absolute;

  .wrapper {
    padding: 2vh 20px;
    max-width: 1469px;
    margin: auto;

    @media @desktop {
      padding: 3vh 20px;
    }
  }

  .logo {
    svg {
      height: 26px;
      @media @desktop {
        height: 36px;
      }
    }
  }

  &.sticky {
    position: fixed;

    .menu {
      max-width: 1469px;
      margin: auto;
    }

    .wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    z-index: 10;

    .button {
      padding: 14.5px 30px;
      white-space: nowrap;
    }

    .toggle {
      background: rgba(242, 237, 232, 0.1);
      z-index: 10;
      cursor: pointer;
      width: 45px;
      height: 45px;
      border: 1.5px solid #fefaf7;
      border-radius: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #fefaf7;
      }

      svg {
        width: 10px;
        height: 10px;
        color: @nueblue;
      }
    }
  }

  .underlay {
    position: absolute;
    transition: transform 200ms @easeInOutQuart;
    background: @sleep;
    transform: translateY(-100%);
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  &.nav_background {
    .underlay {
      transform: translateY(0);
      box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
    }
    .bottom-buttons {
      background: @eggshell;
    }
  }

  &.light-background {
    .logo-with-text {
      svg {
        fill: @nueblue;
        color: @nueblue;
      }
    }
  }

  .logo-with-text {
    svg {
      fill: white;
      color: white;
    }
  }

  .logo-with-text,
  .button {
    position: relative;
    z-index: 100;
    transition: all 0.4s ease-in-out;
  }

  &__foldout {
    nav {
      position: absolute;
      top: 0;
      padding-top: 5em;
      height: 100vh;
      padding-right: 20px;
      z-index: 4;
      text-align: center;
      background: @nueblue;
      color: @eggshell;
      transition: all 0.7s ease-in-out;

      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      text-align: left;
      font-family: @baskervilleRegular;

      transform: translateX(100%);
      width: 100vw;

      @media @desktop {
        right: 0;
        max-width: 512px;
        border-radius: 30px 0px 0px 30px;
      }

      @media only screen and (min-width: 1560px) {
        min-width: calc(500px + (100vw - 1560px) * 0.6);
        width: calc((100vw - 1560px));
        .links {
          //margin-right: calc((100vw - 1449px) / 2);
        }
      }

      .loginCTA {
        @media @desktop {
          text-align: right;
        }

        padding-left: 64px;
        font-family: @baskerville;
        font-size: 30px;
        margin-bottom: 20px;
        a {
          display: block;
          padding: 20px 0;
        }
      }

      .socialmedia {
        flex-direction: row;
        justify-content: space-between;
        margin-left: 64px;
        margin-bottom: 64px;

        @media @mobileLandscape {
          text-align: center;
          margin: auto;
          margin-bottom: 20px;
        }

        align-items: center;
        width: 174px;
        display: flex;
        .icon {
          color: #4b79aa;
        }

        .twitter {
          display: inline-block;
          height: 18px;
          width: 18px;
        }
        .facebook {
          display: inline-block;
          height: 18px;
          width: 18px;
        }
        .linkedin {
          display: inline-block;
          height: 18px;
          width: 18px;
        }
        .instagram {
          display: inline-block;
          height: 18px;
          width: 18px;
        }
        svg {
          height: 100%;
        }
      }
    }

    .nav_background {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2); /* Black w/opacity/see-through */
      // backdrop-filter: blur(5px);
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2;
      left: -100vw;
      cursor: pointer;
      //transition: all 0.6s ease-in-out;
    }

    .contact {
      flex: 0;
    }

    .links {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: right;
      margin-top: 15vh;

      @media @mobileLandscape {
        text-align: center;
        //margin-top: 90px;
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(2, 1fr);
        li {
          line-height: auto;
          font-size: 30px;
        }
      }

      li {
        line-height: 8vh;
        //letter-spacing: -0.6px;
        font-size: 5vh;
        font-family: @baskerville;
        //line-height: 15vh;
        //letter-spacing: -0.6px;
        &.loginCTA {
          font-size: 3vh;
        }
        @media @desktop {
          font-weight: 400;
          font-size: 6vh;
          text-align: right;
          &.loginCTA {
            font-size: 4vh;
          }
        }
      }
    }
  }

  &.open {
    .top-nav-variant__foldout {
      .background {
        left: 0;
      }
      nav {
        transform: translateX(0);
      }
    }

    .toggle {
      .icon svg {
        fill: @nueblue !important;
        color: @nueblue !important;
      }
    }

    .bottom-buttons {
      background: transparent !important;
    }
  }

  .bottom-buttons {
    @media @desktop {
      display: none;
    }
    bottom: 0;
    width: 100%;
    padding: 0.5em;
    padding-bottom: 0.5em;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button {
      position: relative;
      flex: 1;
      .icon {
        top: 8px !important;
      }
    }
  }
}
