@import 'styles/responsive.less';
@import 'styles/variables.less';

.stepStart {
  display: flex;
  flex-direction: column;
  min-height: 600px;

  background: url('assets/start-hand.jpg') #000;
  background-size: cover;
  background-position: top;

  &.checkout {
    background: url('assets/start-cloud.jpg') #000;
    background-size: cover;
    background-position: top;

    .bodycopy2 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  > .section {
    min-height: calc(100vh - 96px);
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  h1 {
    margin-top: 80px;
    max-width: 460px;
  }

  .header {
    flex: 0;
    padding: 20px;
    z-index: 0;
    width: 100%;
  }

  .signupStepContainer {
    flex: 1;
    justify-content: space-around;
  }

  .signupStepContainer__left {
    margin-top: 40px;
    padding: 0 20px;

    ol li {
      list-style: decimal;
    }
  }

  .signupStepContainer__right {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .signupStepContainer__content {
    padding-left: 0;
    p {
      margin-bottom: 20px;
    }
    > .section {
      margin-bottom: 20px;
    }
  }

  .button.submit {
    margin: 0;
    margin-top: 1em;
    margin-right: 1em;
    margin-bottom: 40px;
  }

  @media @desktop {
    &__text {
      margin-top: 70px;
    }

    .bodycopy1 {
      font-family: @gotham;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -0.5px;
      text-align: left;
    }

    .bodycopy2 {
      //styleName: Body Copy Long Read;
      font-family: @gotham;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.5px;
      text-align: left;
    }

    .button.submit {
      margin-top: 34px;
      width: 326px;
    }
  }

  .talkWithUsHeader {
    margin-top: 40px;
    padding: 0 20px;
    max-width: 700px;

    ol li {
      list-style: decimal;
      margin-bottom: 1em;
    }
  }
}
