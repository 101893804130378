@import 'styles/responsive.less';
@import 'styles/variables.less';
@import 'styles/easing.less';


.plansDrawer{

  .plans {
    padding-bottom: 40px;
    color: @nueblue;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__content {
      flex: 1;
      @media @mobile {
        margin-top: 4vh;
      }

      .title {
        padding: 0;
        margin: 0;
        text-align: left;
      }

      p {
        margin-top: 0.5em;
        margin-bottom: 2em;
        font-family: @gothamBook;
        font-size: 8px;
        font-weight: 100;
        line-height: 30px;
        letter-spacing: -0.5px;
      }

      .button {
        width: 105px;
        @media @desktop {
          width: 15px;
        }
      }
    }

    .title {
      padding: 30px;
      margin: 0;
      text-align: left;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      .plan {
//        margin-bottom: 2em;
      }
      @media @mobile {
        gap: 30px;
      }
      @media @desktop {
        flex-direction: row;
        width: 100%;
        padding: 25px;
        .plan {
          margin: 1%;
          width: 23%
        }
      }
    }
  }


}
  